<template>
  <div
    :id="id"
    class="card"
  >
    <div class="card-header">
      <div
        v-if="!isAdmin"
        class="nav-wrapper position-relative end-0"
      >
        <material-button
          class="float-end"
          color="dark"
          :disabled="loading"
          size="sm"
          variant="gradient"
          @click="checkAll(!allChecked)"
        >
          Alle {{ allChecked ? 'uit' : 'aan' }}vinken
        </material-button>
      </div>

      <h5>
        {{ name }}
      </h5>

      <p v-if="isAdmin">
        Een admin heeft ALLE rechten, deze kunnen niet aangepast worden.
      </p>

      <p v-else>
        Hieronder kunt u de rechten aangeven voor deze rol.
      </p>
    </div>

    <div class="card-body p-0">
      <div class="table-responsive px-4 pb-4">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="2">
                <p class="mb-0">
                  Module
                </p>
              </th>

              <th class="text-center">
                <p class="mb-0">
                  Aanmaken
                </p>
              </th>

              <th class="text-center">
                <p class="mb-0">
                  Wijzigen
                </p>
              </th>

              <th class="text-center">
                <p class="mb-0">
                  Verwijderen
                </p>
              </th>

              <th class="text-center">
                <p class="mb-0">
                  Reageren
                </p>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="claim in filteredClaims"
              :key="claim.id"
            >
              <td class="ps-1" colspan="2">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">
                    {{ claim.moduleName }}
                  </span>
                </div>
              </td>

              <td
                v-for="action in Object.values(ACTIONS)"
                :key="action"
              >
                <material-switch
                  v-if="isActionAvailable(claim, action)"
                  :id="`role-${roleId}-claim-${claim.id}-${action}`"
                  v-model="claim[action]"
                  class="mb-0 align-items-center justify-content-center"
                  :disabled="isActionDisabled(claim)"
                  :name="`role-${roleId}-claim-${claim.id}-${action}`"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="!isAdmin"
          class="nav-wrapper position-relative end-0"
        >
          <material-button
            v-if="!isDefault"
            class="mt-2"
            color="danger"
            :disabled="loading"
            size="sm"
            variant="gradient"
            @click="remove"
          >
            Rol verwijderen
          </material-button>

          <material-button
            class="float-end mt-2"
            color="success"
            :disabled="loading"
            size="sm"
            variant="gradient"
            @click="save"
          >
            Aanpassingen opslaan
          </material-button>
        </div>
      </div>

      <role-replacement
        v-if="showReplacement"
        :id="roleId"
        :users-amount="usersAmount"
        :roles="roles"
        @hide="showReplacement = false"
        @remove="$emit('remove')"
      />
    </div>
  </div>
</template>

<script>
const ACTIONS = Object.freeze({
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  REACT: 'react',
});

const MODULES = Object.freeze({
  DASHBOARD: 1,
  NEWS: 2,
  CONTACTS: 3,
  EVENTS: 4,
  CHAT: 5,
  PROJECTS: 6,
  SOCIAL: 7,
  PHOTO_GALLERY: 9,
  NOTES: 11,
  AGENDA: 12,
  POLL: 14,
  COMPANY_GOOGLE_MAPS: 15,
  ATTACHMENTS: 16,
  REGISTER_REQUEST: 17,
  PINBOARD: 18
});

import { mapActions } from 'vuex';
import { getUsers, remove, save } from '@/api/providers/roles';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import RoleReplacement from '@/components/Roles/Replacement.vue';

export default {
  name: 'RoleCard',

  components: {
    MaterialButton,
    MaterialSwitch,
    RoleReplacement,
  },

  props: {
    claims: {
      type: Array,
      required: true,
      validator: (claims) => claims
        .every(
          (claim) => [
            ...Object.values(ACTIONS),
            'id',
            'moduleName',
          ]
            .every(
              (key) => Object.keys(claim).includes(key),
            )
        ),
    },
    id: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    isDefault: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    roleId: {
      type: Number,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'remove',
  ],

  data: () => ({
    loading: false,
    showReplacement: false,
    usersAmount: 0,
  }),

  computed: {
    allChecked() {
      return this.claims
        .every(
          (claim) => Object.values(ACTIONS)
            .every(
              (action) => (
                !this.isActionAvailable(claim, action)
                || this.isActionDisabled(claim)
                || claim[action]
              ),
            ),
        );
    },

    filteredClaims() {
      return this.claims
        .filter(
          ({ moduleId }) => ![
            MODULES.AGENDA,
            MODULES.CHAT,
            MODULES.COMPANY_GOOGLE_MAPS,
            MODULES.CONTACTS,
            MODULES.DASHBOARD,
            MODULES.PHOTO_GALLERY,
            MODULES.SOCIAL,
            MODULES.REGISTER_REQUEST
          ]
            .includes(moduleId));
    },
  },

  created() {
    this.ACTIONS = ACTIONS;
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    checkAll(checked) {
      const actions = Object.values(ACTIONS);

      this.claims.forEach((claim) => {
        actions.forEach((action) => {
          if (
            !this.isActionAvailable(claim, action)
            || this.isActionDisabled(claim)
          ) {
            return;
          }

          claim[action] = checked;
        });
      });
    },

    isActionAvailable(claim, action) {
      const isModuleWithReactAction = [
        MODULES.NEWS,
        MODULES.EVENTS,
        MODULES.PROJECTS,
        MODULES.POLL,
      ].includes(claim.moduleId);

      return (
        (
          !isModuleWithReactAction
          && action !== ACTIONS.REACT
        )
        || isModuleWithReactAction
      );
    },

    isActionDisabled(claim) {
      return (
        this.isAdmin
        || this.loading
        || [
          MODULES.NOTES,
        ].includes(claim.moduleId)
      );
    },

    remove() {
      getUsers(this.roleId)
        .then((users) => {
          if (users?.length) {
            this.usersAmount = users.length;
            this.showReplacement = true;
          } else {
            this.removeWithoutReplacement();
          }
        });
    },

    removeWithoutReplacement() {
      remove(this.roleId)
        .then(() => this.$emit('remove'))
        .catch((error) => error.default());
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      save(this.roleId, this.name, this.claims)
        .then(() => {
          this.loading = false;
          this.addNotification({
            description: `${this.name} is opgeslagen.`,
            icon: {
              component: 'lock_person',
            },
          });
        })
        .catch((error) => error.default());
    },
  },
};
</script>
