<template>
  <form
    class="role-replacement bg-gradient-danger p-4 text-white"
    @submit.prevent="submit"
  >
    <p class="text-sm text-bold">
      Er {{ usersAmount == 1 ? 'is': 'zijn' }} {{ usersAmount }}
      app-gebruiker{{ usersAmount == 1 ? '': 's' }} met deze rol. Welke nieuwe rol mogen zij
      krijgen?
    </p>

    <div class="row">
      <div class="col-6">
        <base-select
          v-model="value"
          :disabled="loading"
          :options="options"
          required
        />
      </div>
      <div class="d-flex col-6 justify-content-end align-items-end">
        <div class="mx-n2">
          <material-button
            class="mx-2"
            color="light"
            :disabled="loading"
            variant="outline"
            @click.prevent="$emit('hide')"
          >
            Annuleren
          </material-button>

          <material-button
            class="mx-2"
            color="light"
            :disabled="loading"
          >
            Verder
          </material-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { remove } from '@/api/providers/roles';
import BaseSelect from '@/components/Forms/BaseSelect.vue';
import MaterialButton from '@/components/MaterialButton.vue';

export default {
  name: 'RoleReplacement',

  components: {
    BaseSelect,
    MaterialButton,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    usersAmount: {
      type: Number,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
      validator: (roles) => roles
        .every(
          (role) => [
            'id',
            'name',
          ]
            .every(
              (key) => Object.keys(role).includes(key),
            )
        ),
    },
  },

  emits: [
    'hide',
    'remove',
  ],

  data: () => ({
    loading: false,
    value: '',
  }),

  computed: {
    options() {
      return this.roles
        .filter(({ id }) => id !== this.id)
        .map(({ id, name }) => ({
          code: id,
          label: name,
        }));
    },
  },

  methods: {
    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      remove(this.id, this.value)
        .then(() => this.$emit('remove'))
        .catch((error) => error.default());
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/material-dashboard';

.role-replacement {
  border-radius: 0 0 $card-border-radius $card-border-radius;
}
</style>
