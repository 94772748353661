<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-9">
          <h5>Standaard-auteur</h5>
          <p>Hier kunt u de standaard-auteur voor nieuwsberichten en projecten selecteren</p>
        </div>
        <div class="col-3">
          <dropdown 
            :options="options"
            v-model:value="defaultAuthorId"
            @update:value="() => this.showSaveButton = true"
          />

          <material-button
            v-if="showSaveButton"
            class="w-100"
            color="dark"
            @click="submit"
          >Opslaan
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';


import Dropdown from '../UI/Dropdown.vue';

import { getAll } from '@/api/providers/users';

import { get as getSettings, edit as editSettings } from '@/api/providers/organization-settings'

import MaterialButton from "@/components/MaterialButton.vue"

export default {
  name: 'DefaultAuthorCard',

  components: { 
    Dropdown,
    MaterialButton
  },

  data() {
    return {
      apiSettings: {},

      defaultAuthorId: undefined,

      options: [],
      showSaveButton: false
    };
  },

  mounted() {
    this.initialize()
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    initialize() {
      getAll()
      .then(apiUsers => {
        this.options = apiUsers.filter(u => u.isAdmin).map(u => {
          return {
            value: u.id,
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName != null ? u.lastName.charAt(0) : ''}.`,
            image: u.profilePicture
          }
        })
      })

    getSettings()
      .then(settings => 
      {
        this.apiSettings = settings
        this.defaultAuthorId = settings.defaultAuthorId
      })
    },

    initializeSettings(settings)
    {
      this.settings = settings;
    },

    submit() {
      editSettings(
        { 
          ...this.apiSettings, 
          defaultAuthorId: this.defaultAuthorId
        }
      ).then(() => {
        this.addNotification({
          description: `Instellingen is opgeslagen.`,
          icon: {
            component: 'settings',
          },
        });
      })
    }
  }
}
</script>